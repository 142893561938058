var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.tree),function(item,key){return _c('div',{key:item.id,staticClass:"tree-item"},[_c('div',{class:['tree-item-name', item[_vm.itemsName] && item[_vm.itemsName].length > 0 ? 'has-icon' : '']},[(item[_vm.itemsName] && item[_vm.itemsName].length > 0)?_c('div',{class:[
                    'glyphicon',
                    'glyphicon-triangle-right',
                    {
                        rotate: _vm.unfold[item.id],
                    },
                ],on:{"click":function($event){return _vm.changeUnfold(item.id)}}}):_vm._e(),(_vm.showCheckbox && (item.showCheckbox == null || item.showCheckbox))?_c('input',{class:[
                    {
                        notAllChecked:
                            !item.checked && _vm.hasChildChecked[item.id],
                        disabled: item.allowCheck != null && !item.allowCheck
                    },
                ],attrs:{"type":"checkbox"},domProps:{"checked":item.checked},on:{"change":function($event){return _vm.changeChecked(_vm.tree, item.allowCheck != null && !item.allowCheck, key)}}}):_vm._e(),_c('span',{staticClass:"itemName ss",on:{"click":function($event){return _vm.clickChecked(_vm.tree, item.allowCheck != null && !item.allowCheck, key)}}},[_vm._v(_vm._s(item[_vm.textName]))])]),(item[_vm.itemsName] && item[_vm.itemsName].length > 0)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.unfold[item.id]),expression:"unfold[item.id]"}],class:['tree-item-child', { hasCheckbox: _vm.showCheckbox }]},[_c('CSTree',{attrs:{"itemsName":_vm.itemsName,"tree":item[_vm.itemsName],"showCheckbox":_vm.showCheckbox,"idName":_vm.idName,"textName":_vm.textName},on:{"change":_vm.onChange}})],1)]:_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }